import React from "react";
import contactIMG from "../assets/images/contact.png"
const Celuk = () => (
  <div className="row">
    <div className="col-12 col-md-12 p-3 text-center">
      <h1>
        <strong>
          <a href="/" className="text-dark">
            Contact
          </a>
        </strong>
      </h1>
    </div>
    <div className="col-12 col-md-8 p-3 text-center">
      <img
        className="img-fluid rounded"
        alt="penjual mesin kasir"
        src={contactIMG}
      />
    </div>
    <div className="col-12 col-md-4 p-3">
      <div className="shadow rounded p-3 p-md-5">
        <p>Email Us: admin@zadolo.com</p>
        {/* <p>WhatsApp : +6285646104747</p>
        <p>Call Us : +6285331361404</p> */}
        {/* <p>
          <a href="https://goo.gl/maps/V2Wxp2RcbF3cHxFC6" target="_blank">
            Visit Showroom here.
          </a>
        </p> */}
      </div>
    </div>
  </div>
);

export default Celuk;
