import React from "react";
import { Helmet } from "react-helmet";
import Aseloley from "./slug/Aseloley";
import Asereje from "./slug/Asereje";
import Aloha from "./slug/Aloha";
import Cinema from "./demo/Cinema";
const Home = () => (
  <div>
    <Helmet>
      <meta
        name="google-site-verification"
        content="ex4TnhMimzJT8jbjjo3HU_r-qfQczMhdYfALH1gyM2s"
      />
      <title>
        Zadolo works on "Innovating the Future of Software Solutions"
      </title>
      <meta
        content="Zadolo works on Innovating the Future of Software Solutions"
        name=" description"
      />
      <meta
        content="Aplikasi kasir , aplikasi toko shop store, aplikasi restoran cafe rumah makan website app"
        property="og:title"
      />
      <meta
        content="Mesin kasir online dengan laravel php artisan untuk kebutuhan aplikasi toko kasir shop store dan aplikasi restoran cafe rumah makan include website all in one."
        property="og:description"
      />
      <meta
        content="Aplikasi kasir , aplikasi toko shop store, aplikasi restoran cafe rumah makan website app"
        property="og:title"
      />
      <meta
        content="Mesin kasir online dengan laravel php artisan untuk kebutuhan aplikasi toko kasir shop store dan aplikasi restoran cafe rumah makan include website all in one.."
        property="og:description"
      />
      <meta
        content="https://1.bp.blogspot.com/-oolLYoq-j5Q/X93ym6YAD8I/AAAAAAAALus/NZ7SFbMNI543KZaqFx129dX0ST4MBQAQACLcBGAsYHQ/w1200-h630-p-k-no-nu/TOUCHSCREEN3.png"
        property="og:image"
      />
      <meta content="https://axcora.com/getaxcoracms" property="og:author" />
      <meta
        content="Aplikasi kasir , aplikasi toko shop store, aplikasi restoran cafe rumah makan website app"
        name="facebook:title"
      />
      <link
        href="https://mesinkasironline.web.app/img/createwebsitelaravel.png"
        rel="icon"
        type="image/x-icon"
      />
      <meta content="website" property="og:type" />
      <meta
        content="https://www.facebook.com/mesinkasircomplete"
        property="facebook:author"
      />
      <meta
        content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
        name="facebook:image"
      />
      <meta
        content="Aplikasi kasir , aplikasi toko shop store, aplikasi restoran cafe rumah makan website app"
        property="og:site_name"
      />
      <meta
        content="Mesin kasir online dengan laravel php artisan untuk kebutuhan aplikasi toko kasir shop store dan aplikasi restoran cafe rumah makan include website all in one."
        property="facebook:description"
      />
      <meta content="summary_large_image" name="twitter:card" />
      <meta
        content="https://twitter.com/hockeycomp"
        property="twitter:author"
      />
      <meta content="@hockeycomp" name="twitter:site" />
      <meta
        content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
        name="twitter:image"
      />
      <meta
        content="Mesin kasir online dengan laravel php artisan untuk kebutuhan aplikasi toko kasir shop store dan aplikasi restoran cafe rumah makan include website all in one."
        name="twitter:description"
      />
      <meta
        content="Aplikasi kasir , aplikasi toko grosir dan eceran retail - mesin kasir online lava apps"
        name="twitter:title"
      />
      <meta content="index, follow" name="robots" />
    </Helmet>
    <Aloha />
    <Asereje />
    {/* <Cinema /> */}
    <Aseloley />
  </div>
);

export default Home;
