import React from "react";
import { Link } from "react-router-dom";
const Asereje = () => (
  <div className="row">
    <div className="col-12 col-md-6 p-3 text-center">
      <div className="Background-Color-Green-Blue text-white rounded p-3 p-md-5">
        <h3>
          <strong>Apps</strong>
        </h3>
        <p>Elevating Everyday Moments, Redefining Digital Experiences.</p>
        <p>
          Zadolo Apps bring the future to your fingertips. Our suite of meticulously crafted mobile applications combines sleek design with powerful functionality, providing users with seamless experiences on both iOS and Android platforms. From intuitive user interfaces to robust features, Zadolo Apps are designed to enhance and simplify your daily life. Whether it's productivity, entertainment, or utility, our apps are built with precision and care to meet the diverse needs of our users. Experience the next level of innovation with Zadolo Apps – where every download is an invitation to a smarter, more connected world.
        </p>
        <Link to="/apps" className="btn btn-light">
          More Info
        </Link>
      </div>
    </div>
    <div className="col-12 col-md-6 p-3 text-center">
      <div className="Background-Color-Green-Blue text-white rounded p-3 p-md-5">
        <h3>
          <strong>Websites</strong>
        </h3>
        <p>Transforming Visions into Seamless Digital Journeys.</p>
        <p>
          Zadolo Websites exemplify the artistry of digital craftsmanship. Tailored to perfection, our websites are more than just an online presence—they're immersive experiences. We blend creativity with functionality, creating dynamic platforms that engage, captivate, and leave a lasting impression. From elegant designs to responsive interfaces, Zadolo Websites redefine the online landscape, empowering businesses to tell their stories with impact. Elevate your brand with a Zadolo Website—where innovation meets the web.
        </p>
        <Link to="/websites" className="btn btn-light">
          More Info
        </Link>
      </div>
    </div>
  </div>
);

export default Asereje;
