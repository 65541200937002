import React from "react";
import Endas from "./slug/Endas";

const Header = () => (
  <div>
    <Endas />
  </div>
);

export default Header;
