import React from "react";
import { Link } from "react-router-dom";
import logoPng from "../assets/images/logo full with zadolo final.png";
const Aloha = () => (
  <div className="">
    <div className="">
      <div className="">
        <div className="Aloha-div1">

          <img
            // className="img-fluid"
            style={{ width: "300px", marginTop: "30px" }}
            alt="zadolo point of sale"
            src={logoPng}
          />




        </div>
        <div className="Aloha-div1 rounded p-3 p-md-5">
          <h1 className="display-2">
            <strong>
              <a href="/" className="Text-Blue">
                Zadolo Apps
              </a>
            </strong>
          </h1>
          <div className="Aloha-div2">
            <h3>
              Zadolo works on "Innovating the Future of Software Solutions"
            </h3>
            <p>
              Welcome to Zadolo, where innovation meets excellence in the world of software development. As a leading software company, we specialize in crafting cutting-edge solutions for iOS, Android, and web platforms. At Zadolo, we are driven by a passion for creating seamless, user-centric experiences that empower businesses and individuals alike. Our team of skilled developers and designers are committed to transforming ideas into reality, delivering high-performance mobile and web applications that redefine the digital landscape. Whether you're envisioning a feature-rich mobile app or a robust web platform, Zadolo is your trusted partner for turning dreams into digital solutions. Elevate your business with Zadolo – Where Ideas Take Flight, and Innovation Finds its Code.
            </p>
          </div>

          {/* <p>Pelajari dan download e-catalaog dibawah ini</p>
          <a
            href="https://mesinkasir.github.io/e-catalog/dexopos%20shop.pdf"
            className="btn btn-primary border border-white"
          >
            Retail
          </a>
          <a
            href="https://mesinkasir.github.io/e-catalog/dexoposrestoapps.pdf"
            className="btn btn-danger border border-white"
          >
            Resto
          </a> */}
        </div>
      </div>
      <div className="Aloha-div1">

        <img
          // className="img-fluid"
          style={{ width: "80vw" }}
          alt="zadolo point of sale"
          src={logoPng}
        />




      </div>

    </div>
  </div>
);

export default Aloha;
