import React from "react";
import { Link } from "react-router-dom";
const Aseloley = () => (
  <div className="row">
    <div className="col-12 col-md-12 p-3 text-center">
      <div className="Background-Color-Green-Blue text-white rounded p-3 p-md-5">
        <h3>
          <strong>Website Management</strong>
        </h3>
        <p>Navigating Digital Success, One Click at a Time</p>
        <p>
          Zadolo Website Management is your compass in the digital realm. Seamlessly steering your online presence, we offer comprehensive solutions to optimize, secure, and elevate your website. From content updates to performance enhancements, our expert team ensures your site stays dynamic and resilient. Trust Zadolo Website Management to navigate the complexities of the digital landscape, so you can focus on what matters most – your business.
        </p>
        <Link to="/website" className="btn btn-light">
          More Info
        </Link>
      </div>
    </div>
  </div>
);

export default Aseloley;
